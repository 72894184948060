.btn {
  border-radius: 999px;
  font-family: "Mukta-Medium";
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 14px;
  padding-right: 14px;
  font-size: 1rem;
  cursor: pointer;
  border: none;
}

.leftIcon {
  margin-right: 8px;
  display: flex;
}

.rightIcon {
  margin-left: 8px;
  display: flex;
}

.selected.light-blue {
  background-color: var(--accent-blue);
  color: white;
}

.selected.light-blue svg,
.selected.light-blue path {
  fill: white;
}

.light-blue {
  background: var(--primary-blue);
  color: var(--accent-blue);
}

.light-blue svg,
.light-blue path {
  fill: var(--accent-blue);
}

.blue {
  /* background: var(--primary-blue); - commented out for now. need clarification for the color change - arjay */
  color: var(--accent-blue);
}

.blue-statement-page {
  background: var(--accent-blue);
  color: var(--primary-blue);
}

.orange {
  background: var(--orange);
}

.green {
  background: var(--green);
}

.red {
  background: var(--red);
}

.pink {
  background-color: var(--accent-pink);
  color: white;
}

.selected.light-pink {
  background-color: var(--accent-pink);
  color: white;
}

.selected.light-pink svg,
.selected.light-pink path {
  fill: white;
}

.pink svg,
.pink path {
  fill: white !important;
}

.light-pink {
  background-color: var(--light-pink);
  color: var(--accent-pink);
}

/* .light-pink svg,
.light-pink path {
  fill: var(--accent-pink);
} */

.light-pink svg {
  fill: var(--accent-pink);
}

.loadingIcon {
  animation: spin 4s infinite;
  display: flex;
  align-items: center;
}

.noIcon {
  padding: 10px 20px;
}

.disabled {
  cursor: not-allowed;
}

.grey {
  background-color: var(--light-grey);
  color: var(--medium-grey-lighter);
}

.grey svg,
.grey path {
  fill: var(--medium-grey-lighter);
}

.selected.blank svg,
.selected.blank path {
  fill: var(--accent-pink);
}
.selected.blank {
  color: #000;
}

.blank {
  background-color: transparent;
  color: #b4b7bc;
}

/* .blank svg,
.blank path {
  fill: var(--soft-pink);
} */

.blank svg {
  fill: var(--soft-pink);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
