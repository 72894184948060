#modal {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 0px 48px 0px #0000001a;
  top: 50%;
  transform: translate(0%, -50%);
  border-radius: 24px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 18px;
  margin-left: 18px;
  left: 0;
  right: 0;
}
.contentContainerBlurred {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #ffffff;
  border-radius: 24px 24px 24px 24px;
  --webkit-filter: blur(3px);
  --moz-filter: blur(3px);
  --o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
}

#titleModal {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  padding-left: 36px;
}

#headerModal {
  width: 100%;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 20px;
  /* margin-right: 140px; */
  /* background: #0DCA9D; */
  /* border-radius: 18px 18px 0px 0px; */
  /* width: 100%; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
}

#headerModaltext {
  width: 100%;
  font-family: "Mukta";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #696f7a;
  padding-left: 36px;
  padding-top: 8px;
}

.separator {
  width: 100%;
  height: 1px;
  background: #dddddd;
}

#verificationCode {
  width: 50%;
  left: 0;
  right: 0;
  margin: auto;
  padding-top: 36px;
  display: flex;
  justify-content: center;
  padding-top: 60px;
}

#errorGetCode {
  text-align: center;
  font-family: "Mukta-Regular";
  font-size: 14px;
  color: #e7038e;
  cursor: pointer;
  padding-top: 12px;
  padding-bottom: 60px;
}

#buttonContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
}

#buttonContainer > button {
  border: none;
  outline: none;
}

#showPinNumber {
  margin-left: 36px;
  width: 203px;
  height: 48px;
  background: #fff0f9;
  border-radius: 999px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #e7038e;
  cursor: pointer;
  /* margin-right: 10px; */
}

#showPinNumber > * {
  margin-left: 18px;
  padding-right: 5px;
}

#modalButtonShow {
  padding-left: 8px;
}

#cancel {
  margin-left: 11px;
  width: 98px;
  height: 48px;
  background: rgba(244, 244, 244, 0.7);
  border-radius: 999px;
  color: #b3b3b3;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
}

.modalOtpContainer {
  width: 80%;
  align-items: center;
  justify-content: center;
}

.modalOptInput {
  border: 1px solid #dddddd;
  min-width: 36px;
  height: 48px;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 6px;
}

@media (min-width: 1024px) {
  #modal {
    max-width: 684px;
    width: 684px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  #titleModal {
    font-size: 32px;
  }
  #headerModalText {
    font-size: 16px;
  }
}

@media screen and (max-width: 1200px) {
  #verificationCode {
    width: 70%;
  }
}

@media screen and (max-width: 800px) {
  #errorMessage {
    font-size: 12px;
  }
  #verificationCode {
    width: 100%;
  }
}

@media screen and (max-width: 760px) {
  #modal {
    /* top: 180px; */
    /* margin: auto; */
  }
}

@media screen and (max-width: 670px) {
  #modal {
    /* width: 100%; */
    /* left: 0px; */
    /* margin: auto; */
  }
}

@media screen and (max-width: 375px) {
  .modalOtpContainer {
    align-items: center;
    justify-content: center;
  }
  #modal {
    /* width: 100%; */
    /* position: absolute; */
    /* background: #FFFFFF; */
    /* box-shadow: 0px 0px 48px 0px #0000001A; */
    /* width: 80%; */
    /* top: 100px; */
    /* left: 0px; */
    /* margin: auto; */
    /* border-radius: 24px; */
    /* z-index: 10; */
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
  }
  #titleModal {
    /* font-size: 18px; */
  }
  #showPinNumber {
    font-size: 14px;
    line-height: 14px;
    width: 172px;
    margin-left: 24px;
  }
  #cancel {
    font-size: 14px;
    line-height: 14px;
  }
}
