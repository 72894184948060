.tagline {
  font-family: "Mukta";
  font-weight: 500;
  font-size: 14px;
  line-height: 112%;
  color: var(--medium-grey2);
  padding-top: 12px;
}

.titleContainer {
  padding: 24px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 26px;
}
.title {
  font-family: "Nunito";
  font-weight: 600;
  font-size: 18px;
}
