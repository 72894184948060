.footer {
  background-color:white;
  padding: 32px 16px 16px 16px;
  box-shadow: 0 2px 64px 0 rgb(0 0 0 / 5%);
}

.logoImage {
  width:45%;
  height:auto;
}

.padding {
  padding-left: 15px;
  padding-right: 15px;
}

.links {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.linksGroup ul {
  list-style-type: none;
}

.linksGroup li {
  /* height:34px; */
  line-height: 2.43;
}

.linksGroup h5 {
  color: var(--accent-blue);
  margin-top: 48px !important;
  margin-bottom: 24px !important;
  font-family: "Nunito";
  font-weight: 800;
  font-size: 14px;
}

.linksGroup a, .footerSocket a {
  color: #000;
  font-size: 14px;
  font-weight: 700;
  font-family:"Mukta"
}

.copyright p{
  color: rgb(108, 117, 125);
  font-family: Nunito-Regular;
  font-size: 12px;
  line-height: 1.4;
  font-weight: 700;
  margin-top: 24px !important;
  margin-bottom: 24px !important;
  text-align: justify;
}

.socialLinks {
  padding-bottom: 48px;
  padding-top: 48px;
}

.socialLinks ul {
  display: flex;
  justify-content:space-between;
  list-style-type: none;
  /* padding: 48px 30px; */
  gap: 8px;
}

.socialLinks li{
  background-color:var(--primary-blue);
  border-radius: 8px;
  cursor:pointer;
}

.socialLinkIcon {
  width: 47px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color);
  border-radius: 8px;
}
.socialLinkIcon img {
  width: 24px;
}


@media(min-width: 768px) {
  .footer .logoImage {
    width: 150px;
    margin-bottom: 36px !important;
  }

  .footerGrid {
    display:grid;
    grid-template-columns: 25% auto;
    gap: 45px;
  }

  .links {
    grid-template-columns: repeat(4, 25%);
  }

  .linksGroup h5 {
    margin-top: 0px !important;
  }

  .socialLinkIcon {
    width: 100px;
    height: 100px;
    border-radius: 20px;
  }

  .socialLinkIcon img {
    width: 54px;
  }

}

@media(min-width:1024px) {
  .footer .logoImage {
    width: 200px;
  }
}
