.fixedBottomAction {
  width: 100%;
  height: 84px;
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  position: sticky;
  bottom: 0;
  padding: 30px 12px;
  margin-top: 40px;
}

@media (min-width: 1024px) {
  .fixedBottomAction {
    height: 92px;
  }
}
