#logo {
  background-color: white;
  display: flex;
  padding-top: 24px;
  padding-bottom: 18px;
  padding-right: 8px;
  width: 100%;

  -webkit-box-shadow: 0 1px 3px #666666;
  -moz-box-shadow: 0 1px 3px #666666;
  box-shadow: 0 1px 3px #666666;
}

.logoImage {
  width: 125px;
}

.headerButtons {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.headerButtons div {
  display: flex;
}

.headerButtons .profileImage img {
  height: 32px;
  width: 32px;
}

.searchInput {
  width: 100%;
  border-bottom: 1px solid var(--medium-grey);
}

.searchOpen {
  width: 100px;
}

.searchClosed {
  min-width: 36px;
  width: auto;
}

@media (min-width: 1024px) {
  .logoImage {
    width: 175px;
  }
  #logo {
    justify-content: center;
  }
  .headerButtons {
    margin-left: 0;
  }
}
