/* fonts */
@font-face {
  font-family: "Nunito-Regular";
  src: local("Nunito-Regular"),
    url("assets/fonts/Nunito-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito-SemiBold";
  src: local("Nunito-SemiBold"),
    url("assets/fonts/Nunito-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito-Bold";
  src: local("Nunito-Bold"),
    url("assets/fonts/Nunito-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Mukta-Regular";
  src: local("Mukta-Regular"),
    url("assets/fonts/Mukta-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Mukta-Medium";
  src: local("Mukta-Medium"),
    url("assets/fonts/Mukta-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Mukta-SemiBold";
  src: local("Mukta-SemiBold"),
    url("assets/fonts/Mukta-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Mukta-Bold";
  src: local("Mukta-Bold"),
    url("assets/fonts/Mukta-Bold.ttf") format("truetype");
}

/* Global styles */
:root {
  --primary-blue: #ddebff;
  --orange: #fbb445;
  --green: #0dca9d;
  --red: #ff7171;
  --black: #808080;

  --extra-light-grey: #f8fbff;
  --light-grey: #f9f9f9f9;

  --light-grey: #f5f4f4;
  --light-pink: #fff0f9;
  --light-blue: #f5f9ff;

  --medium-grey-lighter: #b3b3b3;
  --medium-grey: #b4b7bc;
  --medium-grey2: #696f7a;

  --accent-blue: #086afb;
  --accent-pink: #e7038e;

  --soft-pink: #ffc9e9;

  --lg-breakpoint: 1440px;
}
#root {
  height: 100%;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url(../public/images/bg.png);
  background-size: cover;
  background-clip: border-box;
  position: relative;
}

/* h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0 !important;
} */

a {
  text-decoration: none;
}

button {
  border: none;
}

/* colors */
.text-primary-blue {
  color: var(--primary-blue);
}

.text-accent-blue {
  color: var(--accent-blue);
}

.text-orange {
  color: var(--orange);
}

.text-green {
  color: var(--green);
}

.text-red {
  color: var(--red);
}

.text-black {
  color: var(--black);
}

.bg-light {
  background-color: var(--light-grey);
}

.bg-white {
  background-color: white;
}

/* helpers */
.no-margin {
  margin: 0 !important;
}

/* overrides */
/* .MuiAutocomplete-input {
  width: 100%;
  outline: none;
  border: none;
  background: rgba(201, 201, 201, 0);
  font-family: "Mukta-Regular";
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.5);
  height: 100%;
} */

.MuiAutocomplete-option {
  font-family: "Nunito";
  font-size: 14px;
  min-height: 32px;
}

.MuiInput-underline::before {
  border: none !important;
}

.MuiInput-underline::after {
  border: none !important;
}

.MuiFormControl-root {
  width: 100%;
}

.MuiPickersToolbar-toolbar {
  background-color: #2887f9 !important;
}

.MuiPickersDay-daySelected {
  background-color: #2887f9 !important;
}

.MuiStep-root MuiStep-horizontal css-1bw0nnu-MuiStep-root {
  padding-left: 0;
}

/* .MuiBackdrop-root {
  z-index: 1000 !important;
}
 */
.MuiContainer-root {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

/* other */
.login {
  display: flex;
  align-items: flex-start;
}

.dashboard {
  display: flex;
  align-items: flex-start;
}

.myNumber1Active::after {
  content: "" !important;
  position: absolute !important;
  height: 55px !important;
  width: 1px !important;
  background-color: #e7038e !important;
  transform: translateY(-50%) !important;
  top: -135% !important;
  animation: anima 15s;
}

@keyframes anima {
  0% {
    background-color: #ddd !important;
    height: 0px;
  }
  100% {
    background-color: #e7038e !important;
    height: 55px;
  }
}

@media only screen and (max-width: 991px) {
  .login {
    display: block;
  }
  .dashboard {
    display: block;
  }
  .myNumber1Active::after {
    content: "" !important;
    position: absolute !important;
    width: 60px !important;
    height: 1px !important;
    background-color: #e7038e !important;
    top: 50% !important;
    left: -150% !important;
    transform: translate(-50%, -50%) !important;
    animation: anima 15s;
  }
}

@media only screen and (max-width: 360px) {
  .number::after {
    width: 40px !important;
    left: -170% !important;
  }
}
