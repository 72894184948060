.verificationlaststepContainer {
    background: #ffffff;
    box-shadow: 0px 0px 48px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    margin: 0 auto;
    width: 678px;
    margin-top: 100px;
    position: relative;
}

.titleContainer {
    padding: 25px;
}

.title {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #000000;
}

.tagline {
    font-family: "Mukta";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 112%;
    /* display: flex; */
    /* align-items: center; */
    color: #696f7a;
    padding-top: 0;
    padding-bottom: 12px;
    margin-left: 10px;
}

.imageContainer {
    padding-top: 20px;
    padding-left: 25px;
    /* padding-right: 25px; */
    /* margin-right: 10px; */
}

.imagetext {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 5px;
    padding-right: 20px;
    margin-right: 10px;
}

.imageposition {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-right: 5px;
}

.countries {
    position: absolute;
    /* top: 65px; */
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 20%;
    padding: 15px 0px;
    border-radius: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.country {
    display: block;
    padding: 8px 15px;
    cursor: pointer;
}

.country:hover {
    background-color: #dddddd;
    border-radius: 15px;
}

.verification {
    color: #e7038e;
    cursor: pointer;
    position: relative;
    /* padding: 0 5px; */
    padding-left: 5px;
    padding-right: 5px;
}

.formContainer {
    border-top: 1px solid #dddddd;
}

.inputWrapper {
    padding: 25px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    border-bottom: 1px solid rgba(244, 244, 244, 0.7);
}

.date {
    position: absolute;
}

.countryContainer {
    position: relative;
}

.salutations {
    position: absolute;
    top: 65px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    padding: 15px 0px;
    border-radius: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.salutation {
    display: block;
    padding: 8px 15px;
    cursor: pointer;
}

.salutation:hover {
    background-color: #dddddd;
    border-radius: 15px;
}

.expand {
    position: absolute;
    top: 65px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    padding: 15px 0px;
    border-radius: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.down {
    cursor: pointer;
    width: 16px;
}

.rotate {
    transform: rotate(180deg);
}

.lineVerificstionA {
    width: 100%;
    /* background: rgba(255, 255, 255, 255); */
    /* justify-content: center; */
    display: flex;
    align-items: center;
    /* padding: 0 10px; */
    padding-top: 10px;
    padding-left: 25px;
    padding-bottom: 0px;
    padding-right: 20px;
    /* padding-top: 0;
  padding-bottom: 0px; */
    /* margin: 12px 12px 12px 12px; */
    /* margin-top: 10px; */
    /* margin-right: 12px; */
    margin-bottom: -10px;
    /* margin-left: 6px; */
}

.lineVerificstionB {
    width: 100%;
    /* background: rgba(255, 255, 255, 255); */
    /* justify-content: center; */
    /* display: flex; */
    align-items: center;
    /* padding: 0 10px; */
    padding-top: 0px;
    padding-left: 25px;
    padding-bottom: 40px;
    padding-right: 20px;
}

.lineVerificstionC {
    width: 100%;
    /* background: rgba(255, 255, 255, 255); */
    /* justify-content: center; */
    display: flex;
    align-items: center;
    /* padding: 0 10px; */
    padding-top: 15px;
    padding-left: 0px;
    padding-bottom: 0px;
    padding-right: 20px;
    /* padding-top: 0;
  padding-bottom: 0px; */
    /* margin: 12px 12px 12px 12px; */
}

.lineVerificstionD {
    width: 100%;
    display: flex;
    align-items: center;
    /* padding: 0 10px; */
    padding-top: 1px;
    padding-left: 0px;
    padding-bottom: 0px;
    padding-right: 20px;
    margin-top: -10px;
    /* padding-top: 0;
  padding-bottom: 0px; */
    /* margin: 12px 12px 12px 12px; */
}

.inputContainer {
    width: 100%;
    background: rgba(244, 244, 244, 0.7);
    border-radius: 999px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    margin: 12px 0;
}

.inputContainer input {
    border: none;
    outline: none;
    background-color: transparent;
    padding: 14px 15px;
    width: 100%;
}

.submitContainer {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 24px 24px;
    padding: 25px;
    display: flex;
    justify-content: flex-start;
}

.submitButton {
    width: 90%;
    /* background: rgba(244, 244, 244, 0.7); */
    cursor: pointer;
    color: #e7038e;
    background: #fff0f9;
    border-radius: 999px;
    display: flex;
    align-items: center;
    /* padding: 0 15px; */
    padding-top: 0px;
    padding-right: 20px;
    padding-bottom: 5px;
    padding-left: 28px;
    /* margin: 12px 0; */
    margin-top: 10px;
    margin-right: 12px;
    margin-bottom: 13px;
    margin-left: 6px;
}

.submitButton img {
    margin-right: 8px;
    /* vertical-align: middle; */
}

@media only screen and (max-width: 1199px) {
    /* .countryAndPhone {
    display: grid;
    grid-template-columns: 0.4fr 0.6fr;

    grid-gap: 10px;
  } */
}

@media only screen and (max-width: 991px) {
    .verificationlaststepContainer {
        width: 75%;
        margin-top: 50px;
    }
}

@media only screen and (max-width: 767px) {
    .verificationlaststepContainer {
        width: 90%;
    }
    .inputWrapper {
        grid-template-columns: 1fr;
    }
}

@media only screen and (max-width: 520px) {
    .titleContainer {
        padding: 20px 15px;
    }
    .inputWrapper {
        padding: 20px 12px;
    }
    .inputContainer {
        margin: 6px 0;
    }
    .submitContainer {
        padding: 20px 15px;
    }
}