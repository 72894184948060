.small {
  height: 36px;
  width: 36px;
}

.medium {
  width: 56px;
  height: 56px;
}

.large {
  width: 75px;
  height: 75px;
}

.hasIcon {
  position: relative;
  margin: auto;
}

.shadow {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-width: 2px;
  border-style: solid;
  border-radius: 100%;
}

.iconButton {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0;
}

.iconButton span,
.iconButton button {
  height: 36px;
  width: 36px;
  border-radius: 100%;
  cursor: pointer;
  display: inline-block;
}
.iconButton button svg,
.iconButton button path .iconButton span svg,
.iconButton span path {
  fill: var(--accent-blue);
}

.iconButton svg,
.iconButton path {
  width: 100%;
  height: 100%;
}

.iconButton input {
  display: none;
}
